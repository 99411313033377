/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/pjax@0.2.8/pjax.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/js-storage@1.1.0/js.storage.min.js
 * - /npm/mousetrap@1.6.5/mousetrap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
